import auth from './middleware/auth';
import guest from './middleware/guest';

const routes = [
    {
        name: 'identificacao',
        path: '/',
        component: () => import("./pages/identificacao"),
        meta: {
            middleware: [guest],
        },
    },
    {
        name: 'dashboard',
        path: '/dashboard',
        component: () => import("./pages/dashboard"),
        meta: {
            middleware: [auth],
        },
    },
    {
        name: 'inscricao',
        path: '/inscricao/:selo/:categoria',
        component: () => import("./pages/inscricao"),
        meta: {
            middleware: [auth],
        },
    },
    {
        name: 'download',
        path: '/download/:selo/:categoria',
        component: () => import("./pages/download"),
        meta: {
            middleware: [auth],
        },
    },

]

export default routes
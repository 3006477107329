<template>
  <div class="interna">
    <s-cabecalho />

    <div class="container">
      <div class="row">
        <div class="col-1-1">
          <p class="pt-3 text-center">
            Bem-vindo(a)
            <strong>{{ $store.state.empresa.NomeFantasia }}</strong
            ><small id="sair" @click="sair">SAIR</small>
          </p>
        </div>
      </div>
    </div>

    <slot></slot>

    <s-rodape />
  </div>
</template>
<script>
export default {
  methods: {
    sair() {
      this.$store.commit("sair");
      localStorage.removeItem("turismo_empresa");
      this.$router.push({ name: "identificacao" });
    },
  },
};
</script>
<style lang="scss">
.interna {
  #sair {
    margin-left: 10px;
    color: var(--cor-azul);
    cursor: pointer;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }

  .breadcrumb {
    font-size: 14px;
  }
}
</style>
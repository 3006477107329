<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
  metaInfo: {
    title: "",
    titleTemplate:
      "%s | Turismo Qualificado de BC | Prefeitura de Balneário Camboriú",
    meta: [
      {
        name: "robots",
        content: "index, follow",
      },
    ],
  },

  mounted: function () {
    //se tiver, resgata empresa no localStorage
    var empresa = localStorage.getItem("turismo_empresa");

    if (empresa) {
      empresa = JSON.parse(empresa);
      this.$store.commit("set_empresa", { data: empresa });
    }
  },
};
</script>

<style lang="scss">
@import "@sass/vars.scss";
@import "@sass/base.scss";
@import "@sass/bootstrap.scss";
</style>
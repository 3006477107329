import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
Vue.use(VueAxios, axios)



export default {

    endpoint: 'https://dev.produtorarocket.com/turismoqualificado/api',
    prefix_images: 'https://admin.xxx.com.br/uploads/',
    parametros: [],

    async post(uri, params, callback) {

        var url = this.endpoint + uri;

        console.log('[API POST]', url);

        var ret = await Vue.axios.post(url, params);

        console.log('[API RESPONSE]', ret.data);
        callback(ret.data);
    },

    async get(uri, params, callback) {

        var url = this.endpoint + uri;

        if (params)
            url += '?' + new URLSearchParams(params).toString();

        console.log('[API GET]', url, params);

        var ret = await Vue.axios.get(url);

        console.log('[API RESPONSE]', ret.data);
        callback(ret.data);

    },

    prepare() {
        /**
        var $this = this;

        this.get('parametros', function (data) {
            console.log('[data]', data)
            $this.parametros = data;
        })
        **/
    },

    parametro(id, callback) {

        var $this = this;

        if ($this.parametros.length == 0) {

            setTimeout(function () {
                $this.parametro(id, callback);
            }, 100)

        } else {

            console.log('[parametro]', $this.parametros);

            $this.parametros.forEach(function (param) {
                if (param.Identificador == id)
                    callback(param.Valor);
            });

        }


    },


    /**
     * Instalar use no Vue.
     *
     * @param {Vue} Instancia do Vue
     */
    install(Vue) {
        Vue.prototype.$sdk = this;
        Vue.sdk = this;

        this.prepare();
    }
};
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const store = new Vuex.Store({
    state: {
        empresa: {}
    },

    mutations: {
        set_empresa(state, data) {
            state.empresa = data.data;
        },

        sair(state) {
            state.empresa = {};
        }
    }
});

export default store;